@import url("https://fonts.googleapis.com/css2?family=Outfit&display=swap");

body,
span,
div,
li,
ul,
a,
p {
  font-family: "Outfit", sans-serif !important;
}

:root {
  --card_width: 200px;
  --row_increment: 5px;
  --card_border_radius: 16px;
  --card_small: 26;
  --card_medium: 33;
  --card_large: 45;
}

@media only screen and (max-width: 600px) {
  .feature {
    display: block !important;
  }
  .offre {
    display: none !important;
  }
}

/* Home product list style */
@media (max-width: 600px) {
  .product-list-container {
    display: flex !important;
    flex-direction: row !important;
    align-items: center !important;
    flex-wrap: nowrap !important;
    overflow-y: scroll;
    gap: 25px;
    padding: 0 !important;
  }

  .product-box {
    width: 100% !important;
    padding: 0 !important;
    min-width: 300px;
  }

  .alice-carousel__wrapper > ul > li {
    width: 100% !important;
    position: relative !important;
    /* left: 40%; */
  }
}

/* inspiration gallery style */
@media (max-width: 600px) {
  .inspiration-gallery-container {
    display: grid !important;
    justify-content: center !important;
    grid-template-columns: repeat(auto-fill, minmax(180px, 1fr)) !important;
    grid-auto-rows: var(--row_increment);
  }

  .card {
    padding: 0 !important;
    margin: 15px 1px !important;
    border-radius: var(--card_border_radius) !important;
  }

  .img-small {
    grid-row-end: span var(--card_small) !important;
  }
  .img-medium {
    grid-row-end: span var(--card_medium) !important;
  }
  .img-large {
    grid-row-end: span var(--card_large) !important;
  }

  .css-cro49d-MuiImageListItem-root:nth-of-type(even) {
    height: 100% !important;
  }

  .css-9mgopn-MuiDivider-root {
    display: none !important;
  }

  .react-multi-carousel-track {
    position: relative !important;
    left: 95px !important;
  }
}

/* product details style */

.css-vj1n65-MuiGrid-root {
  /* max-height: 280px !important; */
  width: 100% !important;
  overflow: hidden !important;
}

.css-vj1n65-MuiGrid-root > img {
  object-fit: cover !important;
  width: 100% !important;
}

@media (max-width: 600px) {
  .product-details-container {
    margin-left: 0px !important;
  }

  .product-details-content {
    margin: 0 !important;
  }

  .product-details {
    margin: 0 !important;
  }

  .product-details-title {
    margin: 40px 0 !important;
  }

  .product-details-description {
    margin: 20px 0 !important;
  }

  .products-list-container {
    margin: 0 !important;
  }

  .products-list-content {
    margin-left: 0 !important;
    margin-top: 0 !important;
    gap: 15px;
  }

  .react-multi-carousel-dot-list {
    max-width: 100% !important;
    overflow: scroll !important;
    justify-content: flex-start !important;
    padding: 10px 0 0 0 !important;
  }

  .react-multi-carousel-dot-list > li {
    padding: 5px 10px 0 0 !important;
  }

  .react-multi-carousel-dot-list > li > img {
    object-fit: cover !important;
    width: 100px !important;
    height: 100px !important;
  }

  .css-l7s47x-MuiGrid-root {
    margin-left: 0 !important;
  }

  .css-1iud1mj-MuiGrid-root,
  .css-1ebpurq-MuiGrid-root,
  .css-1csvidg-MuiGrid-root {
    margin-left: 0 !important;
  }

  .css-1csvidg-MuiGrid-root {
    gap: 20px;
  }

  .css-1omn3v1-MuiGrid-root {
    padding-right: 0 !important;
  }

  .css-vj1n65-MuiGrid-root {
    max-height: 190px !important;
    overflow: hidden !important;
  }

  .css-vj1n65-MuiGrid-root > img {
    object-fit: cover !important;
  }

  .css-18qlwaw-MuiGrid-root,
  .css-1iud1mj-MuiGrid-root {
    padding-left: 10px !important;
  }
}

/* magazine style */

@media (max-width: 600px) {
  .css-19r6kue-MuiContainer-root {
    padding: 10px !important;
  }

  .css-cv23yw-MuiContainer-root {
    padding: 0 !important;
  }

  .css-11voc9s-MuiPaper-root-MuiCard-root {
    box-shadow: 3px 3px 6px rgba(0, 0, 0, 0.1) !important;
    margin: 0 10px !important;
  }

  .css-1eoz1c3-MuiGrid-root {
    gap: 20px !important;
  }

  .css-1cvuazx-MuiGrid-root > img {
    object-fit: cover !important;
  }

  .css-y2e0lp-MuiButtonBase-root-MuiButton-root {
    margin: 0 auto !important;
    width: 100%;
  }

  .css-q9irf3-MuiGrid-root {
    width: 100% !important;
    height: 80% !important;
  }

  iframe {
    height: 100% !important;
  }

  .css-btjwr1-MuiTypography-root,
  .css-182szyq-MuiTypography-root-MuiBreadcrumbs-root {
    margin-left: 10px !important;
  }
}

/* product card style */
@media (max-width: 600px) {
  .css-k6r8if {
    height: 80% !important;
    width: 100% !important;
    max-width: none !important;
    border-radius: 20px 20px 0 0 !important;
  }

  .css-14lhohd-MuiModal-root {
    margin: 0 !important;
    width: 100% !important;
  }

  .css-14lhohd-MuiModal-root {
    display: flex !important;
    justify-content: flex-end !important;
    align-items: flex-end !important;
  }
}

.css-1si0ttm-MuiPaper-root-MuiCard-root {
  padding: 10px;
}

.css-2r3irp-MuiCardContent-root:last-child {
  padding-bottom: 5px !important;
}

.css-1lqsg3a-MuiTypography-root {
  font-size: 18px !important;
}

.css-13pp5s3-MuiTypography-root {
  font-size: 15px !important;
}

