@media only screen and (max-width: 600px) {
  .logotitle {
    display: flex;
    width: 25%;
  }
  .name{
    margin: auto 0;
  }
  .about {
    padding-top: 5px;
  }
  .solution{
    margin: 0;
  }
  .contact{
    display: flex;
  justify-content: center;

  }
  .fbb {
    width: 100%;
  }
  .inst {
    width: 100%;
  }
 
}
