
.react-multi-carousel-track {
    max-height: 500px !important;
}
.dots{
    position: relative !important;
    overflow: hidden;
}
.dotsRe{
    position: relative !important;
    padding: 10px;
}
div .offer{
    display: none;
}
  