.alice-carousel__stage-item {
  margin-right: 1em !important;
}

.alice-carousel__stage-item {
  width: 62% !important;
}

.alice-carousel p {
  font-size: 20px;
  background-color: #2b7fa4;
  color: white;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  text-align: center;
}
.alice-carousel p:hover {
  background-color: #2b7fa4;
  color: white;
}
.alice-carousel__dots {
  margin: 15px 20px 10px;

}
.alice-carousel__dots-item.__active {
  height: 10px;
  width: 10px;
  background: #2b7fa4 !important;
}

.alice-carousel__dots-item {
  height: 10px !important;
  width: 10px !important;
  background: #d8d8d8 !important;
}

.alice-carousel__prev-btn {
  display: inline-block !important;
  position: relative;
  margin-left: auto;
  margin-right: 11%;
  width: 1% !important;
  padding: 0px 5px !important;
}

.alice-carousel__next-btn {
  width: 0% !important;
  display: inline-block !important;
  position: relative;
  margin-left: auto;
  margin-right: 8%;
  padding: 0px 5px !important;
}

.alice-carousel__prev-btn-wrapper,
.alice-carousel__next-btn-wrapper {
  position: absolute;
}
/* .itemSlide{
  width: 100% !important;
} */
@media only screen and (max-width: 600px) {
  .alice-carousel__prev-btn-wrapper,
  .alice-carousel__next-btn-wrapper {
    display: none;
  }
  .marquee {
    display: none;
  }
  /* .logo {
    display: none;
  } */
  .slider {
    background: #f9f1e7 !important;
  }
  .sliderin {
    background: #f9f1e7 !important;
  }
}
.MuiBox-root {
  color: #000000;
}
@media only screen and (min-width: 600px) {
  .sliderin {
    background-image: linear-gradient(
      90deg,
      #f9f1e7 63%,
      #fcf8f3 60%
    ) !important;
  }

  .alice-carousel__stage-item {
    /* width: 100% !important; */
        white-space: none ;
  }

  .headerres {
    display: none;
  }
  div > .alice-carousel {
    display: flex !important;
    flex-direction: column-reverse !important;
  }
}
.alice-carousel__stage-item {
  /* width: 100% !important; */
      white-space: inherit !important;
}