@media (max-width: 600px) {
  .feature-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
  }

  .feature {
    display: flex !important;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center !important;
    padding-bottom: 10px;
    border-bottom: 1px solid #c0c1c1;
    min-width: 200px;
  }

  .feature > div > p {
    width: 100% !important;
    display: block !important;
  }
}
